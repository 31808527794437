import React, { Component, Fragment } from "react";
import { Formik, Form } from "formik";
import qs from "qs";
import * as Yup from "yup";
import {
  Text,
  Heading,
  Box,
  Button,
  TextInput,
  CheckBox
} from "adry-commons-ui";
import axios from "axios";

// -1 -> Kaufen Audio
// -2 -> nicht Kaufen Audio
// -3 -> lieber Video
// -4 -> alles kacke lasst mich in ruhe xD

const SignupSchema = Yup.object().shape({
  email: Yup.string()
    .email("Ungültige E-Mail.")
    .required("E-Mail ist ein Pflichtfeld."),
  agree: Yup.bool().oneOf(
    [true],
    "Bitte akzeptiere zunächst die Datenschutzbestimmungen."
  )
});

class Newsletter extends Component {
  state = {
    submitted: false
  };

  render() {
    const { submitted } = this.state;

    return (
      <Box pad="medium">
        <Heading size="small" level={2} alignSelf="center" textAlign="center">
          Bleib auf dem Laufenden
        </Heading>
        <Text textAlign="center" margin="medium">
          <b>Kein Newsletter!</b> Wir melden uns nur, wenn es wirklich was
          Interessantes gibt!
        </Text>
        <Box background="#01a982" pad="large">
          {!submitted && (
            <Fragment>
              <Text size="medium">
                Wenn es kostenpflichtige Hörspiele mit hoher Qualität und
                Spieldauer gibt, können wir uns bei dir melden.
                <br />
                Trage deine Email-Adresse ein und wir schreiben dir wenn es
                soweit ist:
              </Text>
              <Formik
                initialValues={{
                  email: "",
                  agree: false
                }}
                validationSchema={SignupSchema}
                onSubmit={values => {
                  // same shape as initial values
                  this.setState({
                    submitted: true
                  });
                  window.dataLayer.push({
                    event: 'genericEvent',
                    eventCategory: 'newsletter',
                    eventAction: 'signup',
                    eventlabel: '',
                  });

                  // send request to api
                  axios({
                    method: "post",
                    url: "https://mail.shared.audory.io/subscribe",
                    data: qs.stringify({
                      email: values.email,
                      gdpr: true,
                      list: "gpeZktaR3L4Cp8927eHqBzyg",
                      boolean: true
                    }),
                    headers: {
                      "Content-Type": "application/x-www-form-urlencoded"
                    }
                  });
                }}
              >
                {({ values, errors, touched, handleChange }) => (
                  <Form>
                    {((errors.email && touched.email) ||
                      (errors.agree && touched.agree)) && (
                      <Box
                        background="status-error"
                        width="medium"
                        margin={{ top: "medium" }}
                      >
                        <Text size="small">
                          <ul>
                            {errors.email && touched.email && (
                              <li>{errors.email}</li>
                            )}
                            {errors.agree && touched.agree && (
                              <li>{errors.agree}</li>
                            )}
                          </ul>
                        </Text>
                      </Box>
                    )}
                    <Box
                      direction="row"
                      pad={{ vertical: "medium" }}
                      gap="medium"
                    >
                      <Box
                        background="white"
                        round="medium"
                        width="medium"
                        pad={{ horizontal: "small" }}
                      >
                        <TextInput
                          plain
                          name="email"
                          placeholder="E-Mail Adresse"
                          background="white"
                          onChange={handleChange}
                          value={values.email}
                        />
                      </Box>
                      <Button label="Ok" primary color="white" type="submit" style={{wordBreak: 'keep-all'}} />
                    </Box>
                    <Box direction="row">
                      <CheckBox
                        name="agree"
                        onChange={handleChange}
                        checked={values.agree}
                      />
                      <Text size="medium">
                        Ja, ich will die Informations E-Mails zur
                        Audory-Platform und Interaktiven Hörspielen erhalten.
                        <br />
                        <Text size="xsmall">
                          Hinweise zu der mit der Einwilligung mitumfassten
                          Auswertung unserer E-Mails und dem Einsatz unseres
                          Versanddienstleisters SES finden Sie in unserer
                          Datenschutzerklärung.
                        </Text>
                      </Text>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Fragment>
          )}
          {submitted && (
            <Box>
              <Text>
                Vielen Dank!
                <br />
                Du erhälst eine E-Mail um deine Anmeldung zu Bestätigen.
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}

export default Newsletter;
