import React from "react";
import { Box, Heading } from "adry-commons-ui";
import FooterList from "./FooterList";
import FooterGrid from "./FooterGrid";
// import FooterParagraph from "./FooterParagraph";

function Footer({ responsive }) {
  return (
    <Box pad="medium" background="#F7F7F7" fill>
      <FooterGrid size={responsive}>
      {/*
        <Box
          alignSelf="start"
          width="medium"
          gridArea="logo"
          alignContent="start"
        >
          <Heading level={3} size="small" color="#12203C">
            audory
          </Heading>
          <FooterParagraph>
            Audory ist eine Plattform, auf der sich alles um interaktive
            Hörspiele dreht.{" "}
          </FooterParagraph>
        </Box>
        <Box
          alignSelf="start"
          width="100%"
          gridArea="center"
          alignContent="start"
        >
          <Heading level={4} size="small" color="#12203C">
            Mehr von audory
          </Heading>
          <FooterList
            elements={[
              {
                link: "https://www.audory.io/editor",
                text: "Editor Tool"
              },
              {
                link: "https://www.audory.io/books",
                text: "Hörspiele"
              },
              {
                link: "https://www.audory.io/app",
                text: "App"
              },
              { link: "https://www.audory.io/alexa", text: "Alexa Skill" }
            ]}
          />
        </Box>
          */}
        <Box alignSelf="start" gridArea="logo" flex alignContent="start">
          <Heading level={4} size="small" color="#12203C">
            Informationen
          </Heading>
          <FooterList
            elements={[
              {
                link: "mailto:hello@audory.io",
                text: "Kontaktiere uns"
              },
              {
                link: "https://www.audory.io/privacypolicy",
                text: "Datenschutz"
              },
              {
                link: "https://www.audory.io/tos",
                text: "Nutzungsbedingungen"
              },
              { link: "https://www.audory.io/imprint", text: "Impressum" }
            ]}
          />
        </Box>
      </FooterGrid>
    </Box>
  );
}

export default Footer;
