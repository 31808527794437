import React from "react";
import { Box, Logo, Text } from "adry-commons-ui";
import Modal from 'react-responsive-modal';

class Header extends React.Component {
  constructor(props) {
    super(props);
    const urlParamsStr = window.location.search.substring(1);
    if(urlParamsStr !== "") {
      let urlObj = {};
      let pairs = urlParamsStr.split('&');
      for(let i in pairs){
          let split = pairs[i].split('=');
          urlObj[decodeURIComponent(split[0])] = decodeURIComponent(split[1]);
      }
      this.state = {
        open: urlObj.confirmed
      };
    } else {
      this.state = {
        open: false,
      };
    }
    
  }
  
  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
   const { open } = this.state;

    return (
      <Box justify="center">
        <Box alignSelf="center">
          <Logo width="small" />
        </Box>
        <Box
          alignSelf="center"
          align="center"
          width="large"
          pad="medium"
          margin={{ vertical: "medium" }}
        >
          <Text textAlign="center" size="small">
            Audory ist eine Plattform, auf der sich alles um interaktive Hörspiele
            dreht.
            
          </Text>
        </Box>
        <Modal open={open} onClose={this.onCloseModal} center>
          <Box width="medium" align="center" margin="small" pad="small" justify="center">
            Deine Anmeldung wurde bestätigt.
          </Box>
        </Modal>
      </Box>
    );
  }
}

export default Header;
