import React, { Component } from "react";

import { Heading, Box, Icon, Text, Button } from "adry-commons-ui";

class SelectionArea extends Component {
  getGameList(storys) {
    return storys.map((story, i) => {
      return (
        <Box key={i} direction="row" margin="small">
          <Button
            plain
            icon={<Icon color="white" name="CirclePlay" />}
            onClick={() => this.props.onStorySelect(story.id)}
            label={<Text size="small">{story.name}</Text>}
          />
        </Box>
      );
    });
  }

  getCategoryList() {
    return this.props.gameData.map((category, i) => {
      return (
        <Box key={i}>
          <Box>
            <Heading level={4}>{category.category}</Heading>
          </Box>
          <Box style={{ paddingLeft: "20px" }}>
            {this.getGameList(category.storys)}
          </Box>
        </Box>
      );
    });
  }

  render() {
    return (
      <Box pad={{ vertical: "small", horizontal: "small" }}>
        <Box style={{ position: "relative" }}>{this.getCategoryList()}</Box>
      </Box>
    );
  }
}

export default SelectionArea;
