import React from "react";
import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import shortId from "shortid";
import styled from "styled-components";
import FooterListEntry from "./FooterListEntry";

const FooterListUl = styled.ul`
  padding: 0px;
  list-style-type: none;
  margin-top: 0;
`;

function FooterList({ elements }) {
  if (_isEmpty(elements)) return null;

  return (
    <FooterListUl>
      {elements.map(e => (
        <FooterListEntry key={shortId.generate()} link={e.link} text={e.text} />
      ))}
    </FooterListUl>
  );
}

FooterList.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({ link: PropTypes.string, text: PropTypes.string })
  )
};

export default FooterList;
