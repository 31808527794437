import React, { Component } from "react";
import shortId from "shortid";
import {
  ResponsiveContext,
  Heading,
  Box,
  Button,
  TextInput
} from "adry-commons-ui";

import questions from "../../data/questions.json";

class Question extends Component {
  constructor(props) {
    super(props);
    this.currentForm = "";
  }

  onTextInputChange(evt) {
    this.currentForm = evt.target.value;
  }

  getFormInput(index) {
    this.props.onAnswer(index, this.currentForm);
  }

  getAnswers() {
    return questions[this.props.questionID].answers.map((answer, i) => {
      if (typeof answer === "object") {
        return (
          <Box key={shortId.generate()} fill direction="row">
            <Box margin="auto" width="medium">
              <TextInput onChange={this.onTextInputChange.bind(this)} />
            </Box>
            <Button
              onClick={this.getFormInput.bind(this)}
              color="#01a982"
              primary
              label={answer.button}
            />
          </Box>
        );
      } else {
        return (
          <Box key={shortId.generate()} fill>
            <Button
              onClick={this.props.onAnswer.bind(this, i, answer)}
              label={answer}
              color="#01a982"
              primary
              fill
            />
          </Box>
        );
      }
    });
  }

  render() {
    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Box align="center">
            <Heading level={3} size="large">
              {questions[this.props.questionID].question}
            </Heading>
            <Box
              direction={
                !["xlarge", "large", "medium"].includes(size) ? "column" : "row"
              }
              gap="medium"
              fill
            >
              {this.getAnswers()}
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

export default Question;
