import React, { Component } from "react";
import PropTypes from "prop-types";
import { SecondsToMinutes } from "adry-commons-player";

import {
  Box,
  Button,
  Icon,
  Image,
  Layout,
  Paragraph,
  Heading,
  Text
} from "adry-commons-ui";

/**
 * Minimalistic ui player implementation for reference.
 * In the project we should use custom implementations,
 * wether it is debug/preview player, website player or mobile player.
 */

class SquarePlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInventory: false
    };
  }

  render() {
    const {
      isPlaying,
      onClickPause,
      onClickPlay,
      onSkipFront,
      onSkipBack,
      onSkipForward,
      onSkipEnd,
      progress,
      duration,
      question,
      onClickAnwer,
      isFinal,
      onClickReplay,
      inventory,
      extra
    } = this.props;

    let currentDisplay = null;
    if (isFinal) currentDisplay = renderFinal(onClickReplay);
    else if (question) currentDisplay = renderQuestion(question, onClickAnwer);
    else
      currentDisplay = renderPlayer(
        progress,
        duration,
        isPlaying,
        onSkipFront,
        onSkipBack,
        onClickPause,
        onClickPlay,
        onSkipForward,
        onSkipEnd,
        extra
      );

    if (this.state.showInventory) currentDisplay = renderInventory(inventory);

    return (
      <Box background="dark-2" fill>
        {currentDisplay}
        <Button
          style={{ position: "absolute", top: "5px", right: "5px" }}
          icon={
            !this.state.showInventory ? (
              <Icon color="white" name="Briefcase" />
            ) : (
              <Icon color="white" name="Return" />
            )
          }
          onClick={() => {
            this.setState({ showInventory: !this.state.showInventory });
          }}
        />
      </Box>
    );
  }
}

const renderFinal = onClickReplay => {
  return (
    <Box fill background="dark-2">
      <Box background="dark-2">
        <Heading level={2} margin="small">
          Ende
        </Heading>
      </Box>
      <Box fill background="dark-2" pad="medium">
        <Button
          color="dark-1"
          primary
          label="Nochmal spielen"
          onClick={onClickReplay}
          margin="auto"
        />
      </Box>
    </Box>
  );
};

const renderQuestion = (question, onClickAnwer) => {
  return (
    <Box direction="column" fill style={{ height: "433px" }}>
      <Box align="center" fill pad="small">
        <Paragraph textAlign="center" size="large" style={{ margin: "auto" }}>
          {question.value}
        </Paragraph>
      </Box>
      <Box align="center" fill>
        {renderAnswers(question.answers, onClickAnwer)}
      </Box>
    </Box>
  );
};

const renderAnswers = (answers, onClickAnwer) => {
  let answerRows = [];
  for (let i = 0; i < answers.length; i++) {
    if (i + 1 < answers.length) {
      i++;
      answerRows.push(
        <Box key={i - 1} direction="row" fill>
          <Button
            color="dark-1"
            primary
            label={answers[i - 1]}
            onClick={() => onClickAnwer(i - 1)}
            style={{ margin: "auto", minWidth: "40%", minHeight: "40%" }}
          />
          <Button
            color="dark-1"
            primary
            label={answers[i]}
            onClick={() => onClickAnwer(i)}
            style={{ margin: "auto", minWidth: "40%", minHeight: "40%" }}
          />
        </Box>
      );
    } else {
      answerRows.push(
        <Box key={i} direction="row" fill>
          <Button
            color="dark-1"
            primary
            label={answers[i]}
            onClick={() => onClickAnwer(i)}
            style={{ margin: "auto", minHeight: "60%" }}
          />
        </Box>
      );
    }
  }
  return answerRows;
};

const renderPlayer = (
  progress,
  duration,
  isPlaying,
  onSkipFront,
  onSkipBack,
  onClickPause,
  onClickPlay,
  onSkipForward,
  onSkipEnd,
  extra
) => {
  return (
    <Box background="dark-2">
      <Box pad="large">
        <Image fit="contain" src={extra.cover} />
      </Box>

      <Box fill="horizontal">
        <Box
          direction="row"
          fill="horizontal"
          justify="between"
          pad={{ horizontal: "medium" }}
        >
          <Box pad="xsmall">
            <SecondsToMinutes seconds={progress} />
          </Box>
          <Box pad="xsmall">
            <SecondsToMinutes seconds={duration} />
          </Box>
        </Box>
        <Box background="dark-2">
          <Box
            background="white"
            style={{ height: "5px", width: 100 * (progress / duration) + "%" }}
          />
        </Box>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td align="center">
                <Button
                  icon={<Icon color="white" name="ChapterPrevious" />}
                  onClick={() => {
                    onClickPlay();
                    onSkipFront();
                  }}
                />
              </td>
              <td align="center">
                <Button
                  icon={<Icon color="white" name="BackTen" />}
                  onClick={() => {
                    onClickPlay();
                    onSkipBack();
                  }}
                />
              </td>
              <td align="center">
                {(isPlaying && (
                  <Button
                    icon={<Icon color="white" name="Pause" />}
                    onClick={onClickPause}
                  />
                )) || (
                  <Button
                    icon={<Icon color="white" name="Play" />}
                    onClick={onClickPlay}
                  />
                )}
              </td>
              <td align="center">
                <Button
                  icon={<Icon color="white" name="ForwardTen" />}
                  onClick={() => {
                    onClickPlay();
                    onSkipForward();
                  }}
                />
              </td>
              <td align="center">
                <Button
                  icon={<Icon color="white" name="ChapterNext" />}
                  onClick={() => {
                    onClickPlay();
                    onSkipEnd();
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    </Box>
  );
};

const getInventoryListRender = itemList => {
  let list = [];
  for (let i = 0; i < itemList.length; i++) {
    const bgColor = i % 2 === 0 ? "dark-2" : "dark-4";
    list.push(
      <Box
        background={bgColor}
        pad="xsmall"
        style={{ minHeight: "fit-content" }}
      >
        <Layout.Split
          width="2"
          left={<Box background={bgColor}>{itemList[i].name}</Box>}
          right={
            <Box background={bgColor}>
              {itemList[i].type === "int" ? itemList[i].value : null}
            </Box>
          }
        />
      </Box>
    );
  }
  return list;
};

const renderInventory = inventory => {
  return (
    <Box fill background="dark-2" style={{ height: "433px" }}>
      <Box background="dark-2">
        <Heading level={2} margin="small">
          Inventar
        </Heading>
      </Box>
      <Box fill background="dark-2" pad="medium">
        {inventory.itemList.length === 0 ? (
          <Box>
            <Text alignSelf="center">Dein Inventar ist leer</Text>
          </Box>
        ) : (
          getInventoryListRender(inventory.itemList)
        )}
      </Box>
    </Box>
  );
};

SquarePlayer.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  onClickPause: PropTypes.func.isRequired,
  onClickPlay: PropTypes.func.isRequired,
  onSkipFront: PropTypes.func.isRequired,
  onSkipBack: PropTypes.func.isRequired,
  onSkipForward: PropTypes.func.isRequired,
  onSkipEnd: PropTypes.func.isRequired,
  progress: PropTypes.number,
  duration: PropTypes.number,
  question: PropTypes.shape({}),
  onClickAnwer: PropTypes.func.isRequired,
  isFinal: PropTypes.bool.isRequired,
  onClickReplay: PropTypes.func.isRequired,
  variables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  nodeStack: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  inventory: PropTypes.shape({}).isRequired
};

SquarePlayer.defaultProps = {
  progress: 0,
  duration: 0,
  question: null
};

export default SquarePlayer;
