import React from "react";
import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import { ReactPlayer, AudioRenderer } from "adry-commons-player";

import SquarePlayer from "../Player/SquarePlayer";

function PlayerArea({ gameData, cover }) {
  if (_isEmpty(gameData)) return null;

  gameData.cover = cover;

  return (
    <ReactPlayer
      game={gameData}
      uiRenderer={SquarePlayer}
      audioRenderer={AudioRenderer}
      startNode={0}
    />
  );
}

PlayerArea.propTypes = {
  gameData: PropTypes.object
};

export default PlayerArea;
