import React from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "adry-commons-ui";

function FooterGrid({ size, children }) {
  if (!["xlarge", "large", "medium"].includes(size)) {
    return <Box>{children}</Box>;
  }

  return (
    <Grid
      columns={["2fr", "1fr", "1fr"]}
      rows={["flex"]}
      gap="medium"
      areas={[
        {
          name: "logo",
          start: [0, 0],
          end: [1, 0]
        },
        {
          name: "center",
          start: [1, 0],
          end: [1, 0]
        },
        {
          name: "right",
          start: [2, 0],
          end: [2, 0]
        }
      ]}
      fill
    >
      {children}
    </Grid>
  );
}

FooterGrid.propTypes = {
  size: PropTypes.string,
  children: PropTypes.node
};

export default FooterGrid;
