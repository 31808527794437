import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Box, Grommet, ResponsiveContext } from "adry-commons-ui";
import CookieConsent from "react-cookie-consent";

import QuestionField from "../components/QuestionSection/QuestionField";
import Footer from "../components/Footer";
import PlayerSection from "../components/PlayerSection/PlayerSection";
import Header from "../components/Header";
import Newsletter from "../components/Newsletter";

class App extends Component {
  render() {
    return (
      <Grommet>
        <Router>
          <ResponsiveContext.Consumer>
            {size => (
              <Box background="light-1">
                <Box background="light-1" pad={size} width="xlarge" margin="auto">
                  <Header />
                  <PlayerSection responsive={size} />
                  <QuestionField />
                  <Newsletter />
                  <Footer responsive={size} />
                </Box>
              </Box> 
            )}
          </ResponsiveContext.Consumer>
        </Router>
        <CookieConsent
            location="bottom"
            buttonText="Okay"
            cookieName="aud_consent"
            onAccept={() => {window.dataLayer.push({event: 'consentAgree'})}}
            style={{ background: "#2B373B" }}
            buttonStyle={{ background: "#01a982", color: "white", fontSize: "12px" }}
            expires={150}
        >
            Diese Webseite verwendet Marketing-Cookies, zum Auswerten und Verbessern unseres Angebots. <br />
            Nährere Informationen zu Deinen Rechten als Benutzer findest du in unserer <a style={{color: 'white'}} target="_blank" rel="noopener noreferrer" href="https://audory.io/privacypolicy">Datenschutzerklärung</a>.
        </CookieConsent>
      </Grommet>
    );
  }
}

export default App;
