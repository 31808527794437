import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";

const FooterListEntryLi = styled.li`
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #86939e;

  a {
    color: #86939e;
    text-decoration: none;
  }
`;

function FooterListEntry({ link, text }) {
  return (
    <FooterListEntryLi>
      {link.indexOf("http") === -1 ? (
        <Link to={link}>{text}</Link>
      ) : (
        <a href={link} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      )}
    </FooterListEntryLi>
  );
}
FooterListEntry.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string
};

export default FooterListEntry;
