import React, { Fragment } from "react";
import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import { Animated } from "react-animated-css";
import shortId from "shortid";
import axios from "axios";
import { Heading, Box, Logo, Text } from "adry-commons-ui";
import PlayerArea from "./PlayerArea";
import PlayerGrid from "./PlayerGrid";
import SelectionArea from "./SelectionArea";

import gameData from "../../data/gamedata.json";

class PlayerSection extends React.Component {
  constructor(props) {
    super(props);
    const urlParamsStr = window.location.search.substring(1);
    let urlObj = {};
    urlParamsStr.replace(/([^=&]+)=([^&]*)/g, function(m, key, value) {
        urlObj[decodeURIComponent(key)] = decodeURIComponent(value);
    });

    let currentStory = null;
    if(urlObj.play){
      for (let i = 0; i < gameData.length; i++) {
        for (let j = 0; j < gameData[i].storys.length; j++) {
           if(urlObj.play === gameData[i].storys[j].id) currentStory = urlObj.play;
        }
      }
    }
    
    this.gameJsons = [];
    this.state = {
      currentStory: currentStory,
      key: 0
    };

    this.handleSelectStory = this.handleSelectStory.bind(this);
  }
  componentDidMount() {
    this.loadGameData();
  }

  handleSelectStory(id) {
    document.dataLayer &&
      document.dataLayer.push({
        event: "genericEvent",
        eventCategory: "PlayedStories",
        eventAction: id,
        eventLabel: ""
      });
    this.setState({ currentStory: id, key: id });
  }

  async loadGameData() {
    for (let i = 0; i < gameData.length; i++) {
      for (let j = 0; j < gameData[i].storys.length; j++) {
        const response = await axios({
          method: "post",
          url: `https://api.live.audory.io/api/alexa/story`,
          data: {
            storyid: gameData[i].storys[j].id
          }
        });

        const loadedData = {
          ...response.data.response.game_data,
          extra: {
            cover: response.data.response.imageCover
          }
        };
        this.gameJsons.push({
          id: gameData[i].storys[j].id,
          gameData: loadedData
        });
      }
    }
    this.setState({
      currentStory: this.state.currentStory ? this.state.currentStory : gameData[0].storys[0].id,
      key: Math.random()
    });
  }

  render() {
    const { responsive } = this.props;
    const { currentStory } = this.state;
    const isFullWidth = !["xlarge", "large", "medium"].includes(responsive);

    let currentGameJson = null;
    const g = this.gameJsons.find(game => game.id === currentStory);
    if (this.gameJsons.length !== 0 && typeof g !== "undefined")
      currentGameJson = g.gameData;

    return (
      <Fragment>
        <Heading level={3} size="large" textAlign="center" alignSelf="center">
          Wähle ein Hörspiel und probiere es aus!
        </Heading>
        <PlayerGrid size={responsive}>
          <Box background="dark-2" fill gridArea="right">
            {!_isEmpty(currentGameJson) ? (
              <PlayerArea key={shortId.generate()} gameData={currentGameJson} />
            ) : (
              <Box
                fill
                margin="auto"
                alignContent="center"
                direction="column"
                justify="center"
              >
                <Box
                  direction="column"
                  alignSelf="center"
                  height={isFullWidth ? "100vw" : "50vw"}
                  alignContent="center"
                  justify="center"
                >
                  <Box alignSelf="center">
                    <Logo light width="small" />
                  </Box>

                  <Text size="small" textAlign="center" alignSelf="center">
                    <Animated animationIn="pulse infinite">
                      Spiel wird geladen...
                    </Animated>
                  </Text>
                </Box>
              </Box>
            )}
          </Box>
          <Box gridArea="left" background="dark-1">
            <SelectionArea
              gameData={gameData}
              onStorySelect={this.handleSelectStory}
            />
          </Box>
        </PlayerGrid>
      </Fragment>
    );
  }
}

PlayerSection.propTypes = {
  gameJson: PropTypes.object
};

PlayerSection.defaultProps = {
  gameJson: null
};

export default PlayerSection;
