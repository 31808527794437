import React, { Component } from "react";
import Question from "./Question";

import questionGraph from "../../data/questionGraph.json";

import { Box, Text } from "adry-commons-ui";

class QuestionField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQuestion: 0
    };
  }

  getNextQuestionID(index) {
    const currentNode = questionGraph.find(
      question => question.id === this.state.currentQuestion
    );
    return currentNode.outputs[index];
  }

  handleAnswer(index, value) {
    window.dataLayer.push({
        event: "genericEvent",
        eventCategory: "Questions",
        eventAction: `questionID->${this.state.currentQuestion}`,
        eventLabel: `answerValue->${value} index->${index}`
      });
    const nextQuestionID = this.getNextQuestionID(index);
    this.setState({ currentQuestion: nextQuestionID });
  }

  getQuestionPercent() {
    if (this.state.currentQuestion < 0) {
      window.dataLayer.push({
        event: "genericEvent",
        eventCategory: "Questions",
        eventAction: `complete`,
        eventLabel: ``
      });
    }
    return this.state.currentQuestion >= 0
      ? parseInt(100 * (this.state.currentQuestion / questionGraph.length))
      : 100;
  }

  render() {
    let content =
      this.state.currentQuestion >= 0 ? (
        <Question
          questionID={this.state.currentQuestion}
          onAnswer={this.handleAnswer.bind(this)}
        />
      ) : (
        <Box fill>
          <Text margin="auto">Danke für dein Feedback!</Text>
        </Box>
      );
    return (
      <Box background="white">
        <Box
          background="#12203C"
          style={{ width: this.getQuestionPercent() + "%", height: "3px" }}
        />
        <Box pad="medium">{content}</Box>
      </Box>
    );
  }
}

export default QuestionField;
